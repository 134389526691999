import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import AboveFooter from "../components/AboveFooter"

export const SsdlcDevelopmentPhasePageTemplate = ({
  seo,
  hero,
  contactus,
  aboutsection,
  wcwsection,
  duringsection,
  utilizesection,
  staticsection,
  fixedimagesection
}) => (
    <div>
      <SEO title={seo.title} description={seo.description} article_section={seo.article_section} />
      <HeroOther data={hero} />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <SubTitle title={contactus.title} />
              <SubTitle title={contactus.secondtitle} />
            </div>
            <div className="column is-1"></div>
            <div className="column is-3">
              <a href={contactus.buttonlink} >
                <button style={{ fontSize: 20, width: "100%", background: "#53c5d1" }}>{contactus.buttontxt}</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${aboutsection.sectionbg}` }}>
        <div className="container">
          <div style={{ marginTop: "2rem" }}>
            <Title title={aboutsection.title} color={aboutsection.textcolor} />
            <Title title={aboutsection.secondtitle} color={aboutsection.textcolor} />
            <SubTitle title={aboutsection.subheader1} color={aboutsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={aboutsection.descriptions1} color={aboutsection.textcolor} margin="1rem 0" />
            <SubTitle title={aboutsection.subheader2} color={aboutsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={aboutsection.descriptions2} color={aboutsection.textcolor} margin="1rem 0" />
          </div>
          <div className="columns">
            <div className="column is-6"></div>
            <div className="column is-6">
              <div style={{ maxWidth: 300, height: 50 }}>
                <PreviewCompatibleImage imageInfo={aboutsection.image} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${wcwsection.sectionbg}` }}>
        <div className="container">
          <div style={{ marginTop: "10rem" }}>
            <Title title={wcwsection.title} color={wcwsection.textcolor} />
            <SubTitle title={wcwsection.subheader} color={wcwsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={wcwsection.descriptions} color={wcwsection.textcolor} margin="1rem 0" />
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div style={{ marginTop: "3rem" }}>
            <Title title={duringsection.title} />
            <SubTitle title={duringsection.subheader1} margin="1.5rem 0" />
            <Descriptions descriptions={duringsection.descriptions1} margin="1rem 0" />
            <SubTitle title={duringsection.subheader2} margin="1.5rem 0" />
            <Descriptions descriptions={duringsection.descriptions2} margin="1rem 0" />
            <SubTitle title={duringsection.subheader3} margin="1.5rem 0" />
            <Descriptions descriptions={duringsection.descriptions3} margin="1rem 0" />
            <SubTitle title={duringsection.subheader4} margin="1.5rem 0" />
            <Descriptions descriptions={duringsection.descriptions4} margin="1rem 0" />
          </div>
          <div className="columns" style={{ margin: "2rem 0" }}>
            <div className="column is-4"></div>
            <div className="column is-4">
              <a href={duringsection.buttonlink} >
                <button style={{ fontSize: 20, width: "100%", background: "#53c5d1" }}>{duringsection.buttontxt}</button>
              </a>
            </div>
            <div className="column is-4"></div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${utilizesection.sectionbg}`, paddingTop: "5rem" }}>
        <div className="container">
          <div className="columns">
            <div className="column is-4">
              <div style={{ maxWidth: 300, marginTop: 100 }}>
                <PreviewCompatibleImage imageInfo={utilizesection.image} />
              </div>
            </div>
            <div className="column is-8">
              <Title title={utilizesection.title} color={utilizesection.textcolor} />
              <SubTitle title={utilizesection.subheader} color={utilizesection.textcolor} margin="1.5rem 0" />
              <Descriptions descriptions={utilizesection.sidedescriptions} color={utilizesection.textcolor} margin="1rem 0 0" />
            </div>
          </div>
          <div>
            <Descriptions descriptions={utilizesection.descriptions} color={utilizesection.textcolor} margin="0 0 1rem" />
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <Title title={staticsection.title} />
              <SubTitle title={staticsection.subheader1} margin="1.5rem 0 0" />
              <Descriptions descriptions={staticsection.sidedescriptions} margin="1rem 0 0" />
            </div>
            <div className="column is-4">
              <div style={{ maxWidth: 300 }}>
                <PreviewCompatibleImage imageInfo={staticsection.image} />
              </div>
            </div>
          </div>
          <div>
            <Descriptions descriptions={staticsection.descriptions1} margin="-1.5rem 0 1.5rem 0" />
            <SubTitle title={staticsection.subheader2} margin="1.5rem 0" />
            <Descriptions descriptions={staticsection.descriptions2} margin="1rem 0" />
          </div>
        </div>
      </section>
      <AboveFooter data={fixedimagesection} height="300px" />
    </div>
  )

SsdlcDevelopmentPhasePageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  aboutsection: PropTypes.object,
  wcwsection: PropTypes.object,
  duringsection: PropTypes.object,
  utilizesection: PropTypes.object,
  staticsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const SsdlcDevelopmentPhasePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SsdlcDevelopmentPhasePageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        aboutsection={frontmatter.aboutsection}
        wcwsection={frontmatter.wcwsection}
        duringsection={frontmatter.duringsection}
        utilizesection={frontmatter.utilizesection}
        staticsection={frontmatter.staticsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

SsdlcDevelopmentPhasePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default SsdlcDevelopmentPhasePage

export const pageQuery = graphql`
  query SsdlcDevelopmentPhasePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "ssdlc-development-phase" } }) {
      frontmatter {
        seo {
          title
          description
          article_section
        }
        hero{
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        aboutsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader1
          descriptions1
          subheader2
          descriptions2
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        wcwsection {
          sectionbg
          textcolor
          title
          subheader
          descriptions
        }
        duringsection {
          title
          subheader1
          descriptions1
          subheader2
          descriptions2
          subheader3
          descriptions3
          subheader4
          descriptions4
          buttontxt
          buttonlink
        }
        utilizesection {
          sectionbg
          textcolor
          title
          subheader
          sidedescriptions
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        staticsection {
          title
          subheader1
          sidedescriptions
          descriptions1
          subheader2
          descriptions2
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
